import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Location from '../components/Location'
import Section from '../components/Section'
import SEO from '../components/seo'
import SliceZone from '../components/SliceZone'
import { RichText } from 'prismic-reactjs'

const BlogPost = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicPost.edges[0].node
  console.log(document);
  const formatText = (input) => {
    return input[0].toUpperCase() + input.slice(1).replace(/-/g, ' ');
  }
  const SeoTitle = RichText.asText(document.data.meta_title.raw) || formatText(document.uid);
  const SeoDescription = RichText.asText(document.data.meta_description.raw);

  return (
    <Layout>
        <SEO title={SeoTitle} description={SeoDescription} noFollow={true} />
        <Section pageTitle={formatText(document.uid)}>
          <SliceZone sliceZone={document.data.body} />
          <Location />
        </Section>
    </Layout>
  )
}

export const query = graphql`
query ResourceQuery($uid: String) {
  allPrismicPost(filter: { uid: { eq: $uid } }) {
  edges {
      node {
      uid
      data {
        meta_description {
          raw
        }
        meta_title {
          raw
        }
        body {
          ... on PrismicPostBodyText {
            slice_type
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicPostBodyQuote {
            slice_type
            primary {
              quote {
                raw
              }
            }
          }
          ... on PrismicPostBodyFullWidthImage {
            slice_type
            primary {
              full_width_image {
                url
                thumbnails
              }
            }
          }
          ... on PrismicPostBodyImageGallery {
            slice_type
            primary {
              gallery_title {
                raw
              }
            }
            items {
              image {
                url
                thumbnails
                alt
              }
              image_description {
                raw
              }
              link {
                url
                type
                uid
              }
              link_label {
                raw
              }
            }
          }
          ... on PrismicPostBodyImageHighlight {
            slice_type
            primary {
              featured_image {
                url
                thumbnails
                alt
              }
              title {
                raw
              }
              description {
                raw
              }
              link {
                url
                type
                uid
              }
              link_label {
                raw
              }
            }
          }
        }
      }
      }
  }
  }
}
`


export default BlogPost